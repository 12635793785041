import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import { components } from 'react-select';
import useFetch from 'use-http';
import tw from 'tailwind.macro';
import { isMobile } from 'react-device-detect';

import {
  deserializeJSONAPI,
  stringifyJsonApiParams,
} from '../../../services/api';
import { useGlobalState } from '../../../services/GlobalStore/GlobalStore';
import HighlightedOption from './components/HighlightedOption';
import InputGroupWrapper from '../InputGroup';
import clsx from 'clsx';
import { hydrateReactSelectValue } from '../../../services/GlobalStore/reducer';
import ValueWithIcon from './components/ValueWithIcon';
import ClearIndicator from './components/ClearIndicator';
import Input from './components/Input';
import common from '../../../messages/common';
import ControlWithBackButton from './components/Control';

const selectPortal =
  typeof document !== 'undefined'
    ? document?.getElementById('selectPortal')
    : null;

/**
 * Extracts JSONApi ids from built options
 * @param option
 * @returns {*}
 */
export const extractValue = option => {
  if (Array.isArray(option)) {
    return option.map(({ value }) => value);
  }
  return option?.value ?? null;
};

/**
 * Builds JSONApi filter object based on few filters
 * This function is needed when we search options based on few filters
 * @param searchQuery
 * @param searchOnFilters
 * @returns {*}
 */
export const buildFilterObject = (searchQuery, searchOnFilters) =>
  searchOnFilters &&
  searchOnFilters.reduce(
    (acc, currentValue) => ({
      ...acc,
      [currentValue]: searchQuery,
    }),
    {}
  );

/**
 * An object to pass to remove some default styles - other are added by tailwind
 */
const resetReactSelectStyles = {
  container: (provided, { selectProps: { isMobile }, isFocused }) => {
    return {
      ...provided,
      ...(isMobile && isFocused
        ? {
            ...tw`fixed top-0 left-0 w-full bg-white h-100`,
            WebkitBackfaceVisibility: 'hidden',
            webkitBackfaceVisibility: 'hidden',
            BackfaceVisibility: 'hidden',
            backfaceVisibility: 'hidden',
            height: '100vh',
            zIndex: '50',
          }
        : {}),
    };
  },
  valueContainer: provided => ({
    ...provided,
    padding: null,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 8px',
  }),
  clearIndicator: () => ({
    padding: null,
    ...tw`opacity-50 hover:opacity-100 cursor-pointer`,
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  control: (provided, { selectProps: { isMobile }, isFocused }) => ({
    ...provided,
    transition: null,
    borderRadius: null,
    borderColor: null,
    boxShadow: null,
    '&:hover': {
      borderColor: null,
    },
    ...(isMobile && isFocused ? tw`border-0 rounded-none` : {}),
  }),
  menu: (provided, { selectProps: { isMobile } }) => ({
    ...provided,
    marginTop: '-1px',
    boxShadow: null,
    ...(isMobile ? tw`border-0 rounded-none mt-5` : {}),
    ...tw`border border-primary-400`,
  }),
  option: base => ({
    ...base,
    backgroundColor: null,
    color: null,
    ...tw`text-gray-800 cursor-pointer`,
  }),
  placeholder: base => ({
    ...base,
    color: null,
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: null,
    paddingBottom: null,
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999,
  }),
};

const ReactSelectWrapper = ({
  autoComplete,
  additionalQueryParams,
  autoSubmit,
  endpoint,
  filterParams,
  hasError,
  multi,
  openMenuOnClick,
  optionComponent,
  placeholder,
  searchOnFilters,
  valueConstructor,
  defaultOptions,
  disabled,
  prefillWithFirstOption,
  onChangeCallback,
  id,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [entities, dispatch] = useGlobalState(endpoint);
  const documentGlobal = typeof document !== 'undefined' && document;
  const [isFocused, setIsFocused] = useState(false);

  let selectValue = useMemo(() => {
    if (props?.field?.value) {
      if (entities?.[props.field.value]) {
        return valueConstructor(entities[props.field.value]);
      } else {
        let storedLabel = JSON.parse(localStorage.getItem(props.field.name));
        if (storedLabel?.value === props.field.value) {
          return storedLabel;
        }
      }
    } else {
      return null;
    }
    // eslint-disable-next-line
  }, [props?.field?.value, entities]);

  const { get } = useFetch();

  async function handleLoadOptions(searchQuery, callback) {
    // We don't want to fetch any data if there is no searchQuery
    if (!searchQuery && !defaultOptions) {
      callback([]);
      return;
    }

    let url =
      `/${endpoint}?` +
      stringifyJsonApiParams({
        filter: {
          ...filterParams,
          ...(searchQuery
            ? buildFilterObject(searchQuery, searchOnFilters)
            : {}),
          ...(!searchQuery && props?.field?.value
            ? { id: props?.field?.value }
            : {}),
        },
        ...additionalQueryParams,
      });

    let data = await get(url);
    // Build options based on valueConstructor lambda function
    let options = await deserializeJSONAPI(data);
    options = options?.map(valueConstructor);
    callback(options);

    // sometimes we want to automatically prefill a field value with the first option
    if (
      defaultOptions &&
      options?.length &&
      options?.length === 1 &&
      prefillWithFirstOption
    ) {
      onChange(options[0]);
    }
  }

  const debouncedLoadOptions = debounce(handleLoadOptions, 300);

  function getOptions(searchQuery, callback) {
    debouncedLoadOptions(searchQuery, callback);
  }

  /**
   * onChange function of react-select
   * It detects what form type is used and uses correct function to set value of
   * @param option
   */
  function onChange(option) {
    // We only have to check if newOption is array or not and always extract value
    let formValue = extractValue(option);

    const { name } = props?.field;

    if (onChangeCallback) {
      onChangeCallback(formValue);
    }

    // Formik
    if (props.field && props.form) {
      props.form.setFieldTouched(name, true, false);
      props.form.setFieldValue(name, formValue, !autoSubmit);
    }

    // Save label to globalStore
    if (option) {
      dispatch(hydrateReactSelectValue(endpoint, formValue, option));
    }

    if (autoSubmit) {
      props.form.submitForm();
    }
  }

  function onFocus() {
    document.body.style.overflowY = 'hidden';
    if (isMobile) {
      setIsFocused(true);
    }
  }

  function onBlur() {
    document.body.style.overflowY = 'initial';
    if (isMobile) {
      setIsFocused(false);
    }
  }

  const Component = props.creatable ? AsyncCreatable : Async;

  const component = (
    <Component
      loadOptions={getOptions}
      defaultOptions={defaultOptions}
      multi={multi}
      isMobile={isMobile}
      {...props}
      onChange={onChange}
      isClearable
      className={clsx('react-select transform-none', {
        'react-select--has-error': hasError,
        'webkit-backface-visibility': isMobile,
        'bg-white': isMobile && isFocused,
      })}
      style={{
        webkitBackfaceVisibility: 'hidden',
        backgroundColor: 'blue',
      }}
      classNamePrefix={'form-input'}
      value={selectValue}
      placeholder={placeholder?.id ? formatMessage(placeholder) : placeholder}
      backspaceRemovesValue
      styles={resetReactSelectStyles}
      components={{
        Option: optionComponent,
        Control:
          isMobile && isFocused ? ControlWithBackButton : components.Control,
        IndicatorSeparator: null,
        DropdownIndicator: null,
        ValueContainer: ValueWithIcon,
        ClearIndicator,
        Input,
      }}
      menuPortalTarget={documentGlobal && documentGlobal.body}
      openMenuOnClick={openMenuOnClick}
      autoComplete={autoComplete}
      menuShouldScrollIntoView={false}
      menuShouldBlockScroll={isMobile}
      maxMenuHeight={isMobile ? 9999 : undefined}
      // eslint-disable-next-line react/jsx-no-bind
      noOptionsMessage={() => null}
      // eslint-disable-next-line react/jsx-no-bind
      formatCreateLabel={
        props.creatable
          ? value => formatMessage(common.createOption, { value })
          : undefined
      }
      blurInputOnSelect={true}
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={isFocused}
      isDisabled={disabled}
      inputId={id}
    />
  );

  if (isMobile && isFocused) return createPortal(component, selectPortal);

  return component;
};

ReactSelectWrapper.defaultProps = {
  autoComplete: 'on',
  autoSubmit: false,
  creatable: false,
  defaultOptions: false,
  hideIconOnValue: false,
  openMenuOnClick: false,
  optionComponent: HighlightedOption,
  prefillWithFirstOption: false,
};

ReactSelectWrapper.propTypes = {
  additionalQueryParams: PropTypes.object,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoSubmit: PropTypes.bool,
  creatable: PropTypes.bool,
  defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  endpoint: PropTypes.string.isRequired,
  field: PropTypes.object,
  filterParams: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  hideIconOnValue: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeCallback: PropTypes.func,
  openMenuOnClick: PropTypes.bool,
  optionComponent: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  prefillWithFirstOption: PropTypes.bool,
  searchOnFilters: PropTypes.array,
  valueConstructor: PropTypes.func.isRequired,
};

export { ReactSelectWrapper };

// eslint-disable-next-line
const ReactSelectWrapperField = props => (
  <InputGroupWrapper {...props} component={ReactSelectWrapper} />
);

export default ReactSelectWrapperField;
