import { defineMessages } from 'react-intl';
import { steps } from '../utils/routes';

export default defineMessages({
  [steps.TicketRefund]: {
    id: 'cc4.seo.route.ticket-refund',
    defaultMessage: 'ticket-refund',
  },
  [steps.Authorization]: {
    id: 'cc4.seo.route.authorization',
    defaultMessage: 'authorization',
  },
  [steps.Account]: {
    id: 'cc4.seo.route.account',
    defaultMessage: 'account',
  },
  [steps.Profile]: {
    id: 'cc4.seo.route.my-profile',
    defaultMessage: 'my-profile',
  },
  [steps.Password]: {
    id: 'cc4.seo.route.password',
    defaultMessage: 'password',
  },
  [steps.Passenger]: {
    id: 'cc4.seo.route.passenger',
    defaultMessage: 'passenger',
  },
  [steps.UploadId]: {
    id: 'cc4.seo.route.upload-id',
    defaultMessage: 'upload-identification',
  },
  [steps.UploadBookingConfirmation]: {
    id: 'cc4.seo.route.upload-booking-confirmation',
    defaultMessage: 'upload-booking-confirmation',
  },
  [steps.UploadBoardingPass]: {
    id: 'cc4.seo.route.upload-boarding-pass',
    defaultMessage: 'upload-boarding-pass',
  },
  [steps.UploadPaymentReceipt]: {
    id: 'cc4.seo.route.upload-payment-receipt',
    defaultMessage: 'upload-payment-receipt',
  },
  [steps.UploadDelayConfirmation]: {
    id: 'cc4.seo.route.upload-delay-confirmation',
    defaultMessage: 'upload-delay-confirmation',
  },
  [steps.UploadBirthCertificate]: {
    id: 'cc4.seo.route.upload-birth-certificate',
    defaultMessage: 'upload-birth-certificate',
  },
  [steps.UploadOtherDocument]: {
    id: 'cc4.seo.route.upload-other-documents',
    defaultMessage: 'upload-other-documents',
  },
  [steps.Claim]: {
    id: 'cc4.seo.route.shareable-claim-page',
    defaultMessage: 'claim',
  },
  [steps.Claims]: {
    id: 'cc4.seo.route.claims',
    defaultMessage: 'claims',
  },
  [steps.Documents]: {
    id: 'cc4.seo.route.documents',
    defaultMessage: 'documents',
  },
  [steps.MailUploadFlightDocuments]: {
    id: 'cc4.seo.route.mail-upload-flight-documents',
    defaultMessage: 'mail-upload-flight-documents',
  },
  [steps.ClaimsOverview]: {
    id: 'cc4.seo.route.claims-overview',
    defaultMessage: 'claims-overview',
  },
  [steps.ClaimPassengerList]: {
    id: 'cc4.seo.route.claims-passenger-list',
    defaultMessage: 'claim-passenger-list',
  },
  [steps.ClaimDocuments]: {
    id: 'cc4.seo.route.claim-documents',
    defaultMessage: 'documents',
  },
  [steps.BookingDetails]: {
    id: 'cc4.seo.route.claims-booking-details',
    defaultMessage: 'booking-details',
  },
  [steps.FlightItinerary]: {
    id: 'cc4.seo.route.claims-flight-itinerary',
    defaultMessage: 'flight-itinerary',
  },
  [steps.ConnectingFlight]: {
    id: 'cc4.seo.route.claims-connecting-flight',
    defaultMessage: 'connecting-flight',
  },
  [steps.CoBranding]: {
    id: 'cc4.seo.route.cobranding',
    defaultMessage: 'cobranding',
  },
  [steps.MainBooker]: {
    id: 'cc4.seo.route.mainbooker-info',
    defaultMessage: 'main-booker',
  },
  [steps.Minor]: {
    id: 'cc4.seo.route.minor-info',
    defaultMessage: 'minor/:minorId',
  },
  [steps.Guardian]: {
    id: 'cc4.seo.route.legal-guardian-info',
    defaultMessage: 'guardian',
  },
  [steps.PassengerDocuments]: {
    id: 'cc4.seo.route.passenger-documents',
    defaultMessage: 'documents',
  },
  [steps.ThankYou]: {
    id: 'cc4.seo.route.passenger-thank-you',
    defaultMessage: 'thank-you',
  },
  [steps.FlightCompensation]: {
    id: 'cc4.seo.route.flight-compensation',
    defaultMessage: 'flight-compensation',
  },
  [steps.ConnectingFlights]: {
    id: 'cc4.seo.route.connecting-flights',
    defaultMessage: 'flights/:flightIndex',
  },
  [steps.ReplacementFlight]: {
    id: 'cc4.seo.route.claims-replacement-flight',
    defaultMessage: 'replacement-flight',
  },
  [steps.AlternativeFlights]: {
    id: 'cc4.seo.route.alternative-flights',
    defaultMessage: 'alternative-flights/:flightIndex',
  },
  [steps.AlternativeFlight]: {
    id: 'cc4.seo.route.alternativeFlight',
    defaultMessage: 'alternative-flight',
  },
  [steps.CheckCompensation]: {
    id: 'cc4.seo.route.check-compensation',
    defaultMessage: 'check-compensation',
  },
  [steps.CongratulationsBasepath]: {
    id: 'cc4.seo.route.congratulations-basepath',
    defaultMessage: 'congratulations',
  },
  [steps.ClaimAmount]: {
    id: 'cc4.seo.route.claim-amount',
    defaultMessage: 'claim-data',
  },
  [steps.Permission]: {
    id: 'cc4.seo.route.permission',
    defaultMessage: 'permission',
  },
  [steps.PassengersList]: {
    id: 'cc4.seo.route.passengers-list',
    defaultMessage: 'passengers-list',
  },
  [steps.UploadDocuments]: {
    id: 'cc4.seo.route.upload-documents',
    defaultMessage: 'upload-documents',
  },
  [steps.BookingReference]: {
    id: 'cc4.seo.route.booking-reference',
    defaultMessage: 'booking-reference',
  },
  [steps.Login]: {
    id: 'cc4.seo.route.login',
    defaultMessage: 'login',
  },
  [steps.AbandonedCart]: {
    id: 'cc4.seo.route.abandoned-cart',
    defaultMessage: 'finish-your-claim',
  },
  [steps.Sorry]: {
    id: 'cc4.seo.route.sorry',
    defaultMessage: 'sorry',
  },
  [steps.Itinerary]: {
    id: 'cc4.seo.route.itinerary',
    defaultMessage: 'itinerary',
  },
  [steps.Questionnaire]: {
    id: 'cc4.seo.route.questionnaire',
    defaultMessage: 'questionnaire',
  },
  [steps.Error]: {
    id: 'cc4.seo.route.error',
    defaultMessage: 'error',
  },
  [steps.HelpCenter]: {
    id: 'cc4.seo.route.help-center',
    defaultMessage: 'help',
  },
  [steps.Messages]: {
    id: 'cc4.seo.route.messages',
    defaultMessage: 'messages',
  },
  [steps.ForgotPassword]: {
    id: 'cc4.seo.route.forgot-password',
    defaultMessage: 'forgot-password',
  },
  [steps.SetNewPassword]: {
    id: 'cc4.seo.route.set-new-password',
    defaultMessage: 'set-new-reset-password',
  },
  [steps.Unsubscribe]: {
    id: 'cc4.seo.route.unsubscribe',
    defaultMessage: 'unsubscribe',
  },
  [steps.PaymentConfirmation]: {
    id: 'cc4.seo.route.payment-confirmation',
    defaultMessage: 'payment-confirmation',
  },
  [steps.NoPaymentReceived]: {
    id: 'cc4.seo.route.no-payment-received',
    defaultMessage: 'no-payment-received',
  },
  [steps.UploadAdditionalCostAlternativeFlight]: {
    id: 'cc4.seo.route.upload-additional-costs',
    defaultMessage: 'upload-additional-costs',
  },
});
